import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useCountry, useCountries } from '@backpackjs/storefront';

import { Image, Svg, Spinner } from '@snippets';
import { useGlobalContext } from '@contexts';

export function CountrySelector({
  handleOnChange,
  showFlags = false,
  showCountryName = false,
  showCurrency = false,
  color = 'text-white',
  ...props
}) {
  const ref = useRef(null);

  const {
    state: { selectedCountry },
    actions: { setSelectedCountry },
  } = useGlobalContext();

  const countries = useCountries() || [];
  const { country, updateCountry, ...updateStatus } = useCountry();
  const haveCountries = countries?.length > 0;
  const router = useRouter();

  const initialCountry = useMemo(() => {
    if (selectedCountry?.name) {
      return selectedCountry;
    }
    if (country?.name) {
      return country;
    }
    return null;
  }, []);

  const [currentCountry, setCurrentCountry] = useState(initialCountry);

  const [isOpen, setIsOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const countryOptions = useMemo(() => {
    if (!countries?.length) return null;

    const sortedCountries = [...countries].sort((a, b) => {
      if (a.isoCode < b.isoCode) {
        return -1;
      }
      if (a.isoCode > b.isoCode) {
        return 1;
      }
      return 0;
    });

    return sortedCountries;
  }, [countries?.length]);

  const updateSelectedCountry = useCallback(
    async (selected) => {
      try {
        if (!selected?.name || isUpdating) return;
        setIsUpdating(true);
        setIsOpen(false);

        await updateCountry({
          country: selected,
        });

        setCurrentCountry(selected);
        setSelectedCountry(selected);

        if (typeof handleOnChange === 'function') {
          handleOnChange();
        }
        setIsUpdating(false);
      } catch (error) {
        console.error(error.message);
        setIsUpdating(false);
      }
    },
    [isUpdating]
  );

  useEffect(() => {
    if (country?.name !== currentCountry?.name) {
      setCurrentCountry(country);
    }
    if (country?.name !== selectedCountry?.name) {
      setSelectedCountry(country);
    }
  }, [country]);

  useEffect(() => {
    const outsideClickHandler = (e) => {
      if (!ref.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      document.addEventListener('click', outsideClickHandler);
    } else {
      document.removeEventListener('click', outsideClickHandler);
    }
    return () => {
      document.removeEventListener('click', outsideClickHandler);
    };
  }, [isOpen]);

  useEffect(() => {
    if (router?.query?.country) {
      const countryParam = countries.find(
        (country) => country.isoCode === router.query.country
      );
      setCurrentCountry(countryParam);
      updateSelectedCountry(countryParam);
      setSelectedCountry(countryParam);
    }
  }, [countries, router?.query?.country]);

  if (!currentCountry?.name && !selectedCountry?.name) return null;
  if (!countries || countries?.length < 2) return null;

  return haveCountries && countryOptions ? (
    <div ref={ref} className="relative w-full">
      <button
        aria-label="Open account menu"
        className="flex h-12 w-full items-center justify-between gap-4 rounded-full border border-border px-5 py-3 font-montserrat text-xs font-medium uppercase tracking-[2.4px]"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        type="button"
      >
        <div className="flex items-center gap-1">
          {showFlags && (
            <Image
              alt={selectedCountry?.name}
              className="w-4"
              crop="center"
              src={`/svgs/noprecache/flags/${selectedCountry?.isoCode}.svg`}
              width="16"
              height="10"
            />
          )}

          {showCountryName && (
            <p className={`text-sm  ${color}`}>{selectedCountry?.name}</p>
          )}
          {showCurrency && <p>{selectedCountry?.locale?.currency}</p>}
        </div>

        <Svg
          className={`w-4 ${color}${isOpen ? 'rotate-180' : ''}`}
          src="/svgs/chevron-down.svg#chevron-down"
          title="Chevron"
          viewBox="0 0 24 24"
        />
      </button>

      <div
        className={`absolute left-0 top-[calc(100%+0.5rem)] z-10 w-full rounded-[0.5rem] border border-gray bg-background text-base transition duration-100 ${
          isOpen
            ? 'pointer-events-auto translate-y-0 opacity-100'
            : 'pointer-events-none -translate-y-1 opacity-0'
        }`}
      >
        <ul className="flex max-h-[18rem] flex-col gap-0 overflow-y-auto py-2">
          {countryOptions.map((_country, index) => {
            const isActive = _country.value === selectedCountry?.isoCode;

            return (
              <li key={index} className="flex">
                <button
                  aria-hidden={isOpen ? 'true' : 'false'}
                  className={`flex w-full px-5 py-1.5 text-left font-montserrat text-xs font-medium uppercase tracking-[2.4px] transition md:hover:bg-offWhite ${
                    isActive ? 'bg-lightGray' : 'bg-transparent'
                  }`}
                  onClick={() => {
                    updateSelectedCountry(_country);
                    setIsOpen(false);
                  }}
                  tabIndex={isOpen ? '0' : '-1'}
                  type="button"
                >
                  <div className="flex items-center gap-1">
                    {showFlags && (
                      <Image
                        alt={_country?.name}
                        className="w-4"
                        crop="center"
                        src={`/svgs/noprecache/flags/${_country?.isoCode}.svg`}
                        width="16"
                        height="10"
                      />
                    )}

                    {showCountryName && (
                      <p className="text-sm text-black">{_country?.name}</p>
                    )}
                    {showCurrency && <p>{_country?.locale?.currency}</p>}
                  </div>
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  ) : (
    <div className="flex h-[44px] w-full max-w-[150px] items-center justify-center">
      <Spinner height="20px" />
    </div>
  );
}

CountrySelector.displayName = 'CountrySelector';
