import { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Svg } from '@snippets/Svg';

export function PortalModal({
  title,
  showModal = false,
  onClose,
  children,
  className = '',
  ...props
}) {
  const [isBrowser, setIsBrowser] = useState(false);
  const modalWrapperRef = useRef();

  const handleCloseClick = (e) => {
    e.preventDefault();
    onClose();
  };

  const backDropHandler = (e) => {
    if (modalWrapperRef.current && modalWrapperRef.current.contains(e.target)) {
      onClose();
    }
  };

  useEffect(() => {
    setIsBrowser(true);
    window.addEventListener('click', backDropHandler);
    return () => window.removeEventListener('click', backDropHandler);
  }, []);

  const modalContent = showModal && (
    <div
      className={`fixed left-1/2 top-1/2 z-50 max-h-[calc(var(--viewport-height)-2rem)] w-[calc(100%-2rem)] max-w-[48rem] -translate-x-1/2 -translate-y-1/2 overflow-hidden rounded-[10px] bg-background p-4 ${className}`}
      {...props}
    >
      <button
        aria-label="Close modal"
        className="absolute right-1 top-1  z-10 flex h-7 w-7 items-center justify-center"
        onClick={handleCloseClick}
        type="button"
      >
        <Svg
          className="w-4 text-text"
          src="/svgs/close.svg#close"
          title="Close"
          viewBox="0 0 24 24"
        />
      </button>

      <div className="scrollbar-hide max-h-[calc(var(--viewport-height)-2rem)] overflow-y-auto">
        {children}
      </div>
    </div>
  );

  if (isBrowser) {
    return ReactDOM.createPortal(
      modalContent,
      document.getElementById('modal-root')
    );
  }
  return null;
}

PortalModal.displayName = 'PortalModal';
