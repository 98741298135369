import { useCallback, useEffect, useState } from 'react';

import { useGlobalContext } from '@contexts';

export const useGeolocation = () => {
  const [userHeaders, setUserHeaders] = useState();
  const {
    state: { selectedCountry },
    actions: { setSelectedCountry },
  } = useGlobalContext();

  const getHeadersLocation = useCallback(async () => {
    try {
      const response = await fetch('/api/geolocation');
      const data = await response.json();
      return data || null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }, []);

  const getUserCountry = useCallback(async () => {
    const data = await getHeadersLocation();
    setUserHeaders({ isoCode: data?.country });
    if (!selectedCountry?.name) {
      setSelectedCountry({ isoCode: data?.country });
    }
  }, []);

  useEffect(() => {
    getUserCountry();
  }, []);

  return {
    userHeaders,
    selectedCountry,
  };
};
