import { useMemo } from 'react';

import { useLocalizedVariant } from '@backpackjs/storefront';

import { formatAmount } from '@utilities';

export function useVariantPrices({ variant }) {
  const { id, priceV2, compareAtPriceV2 } = { ...variant };

  const { localized: localizedVariant, ...localeStatus } = useLocalizedVariant({
    variant,
  });

  return useMemo(() => {
    if (!priceV2?.amount) {
      return { price: null, compareAtPrice: null };
    }

    const amount = localizedVariant
      ? parseFloat(localizedVariant.priceV2.amount)
      : parseFloat(priceV2.amount);
    const compareAtAmount = localizedVariant
      ? parseFloat(localizedVariant.compareAtPriceV2?.amount || '0')
      : parseFloat(compareAtPriceV2?.amount || '0');

    return {
      price: formatAmount({
        amount,
        currencyCode: localizedVariant?.priceV2?.currencyCode,
      }),
      compareAtPrice:
        compareAtAmount > amount
          ? formatAmount({
              amount: compareAtAmount,
              currencyCode: localizedVariant?.priceV2?.currencyCode,
            })
          : null,
    };
  }, [id, localizedVariant]);
}
