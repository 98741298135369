import { useState, useEffect } from 'react';
import { useSettings } from '@backpackjs/storefront';

import { CountrySelector, PortalModal } from '@snippets';

import { useGeolocation, useLocalStorage } from '@hooks';

export function GeolocationModal() {
  const settings = useSettings();
  const {
    heading = '',
    subheading = '',
    countrySelector = true,
    locations = [],
  } = { ...settings?.header?.userLocationModal };

  const { userHeaders } = useGeolocation();

  const [modalClosed, setModalClosed] = useLocalStorage(
    'countryModalClosed',
    false
  );
  const [openModal, setOpenModal] = useState(true);

  const isUSA = userHeaders?.isoCode === 'US';

  const hideInThisCountry =
    locations.length >= 0 && locations.includes(userHeaders?.isoCode);

  const showModal = !isUSA || !hideInThisCountry;

  const handleOnClose = () => {
    setModalClosed(true);
    setOpenModal(false);
  };

  const handleCountryChange = () => {
    handleOnClose();
  };

  useEffect(() => {
    if (userHeaders?.isoCode) {
      setOpenModal(!modalClosed && showModal);
    }
  }, [hideInThisCountry, userHeaders?.isoCode, locations]);

  if ((!heading && !subheading) || !showModal || modalClosed) return null;

  return (
    <PortalModal showModal={openModal} onClose={handleOnClose}>
      <div
        data-comp={GeolocationModal.displayName}
        className="bg-background_medium mx-auto min-h-[500px] w-full min-w-[300px] max-w-[400px] overflow-auto p-10 text-center"
      >
        <h2 className="mb-6 text-center">{heading}</h2>
        {subheading && <p className="my-8">{subheading}</p>}
        <div className="mt-12 flex justify-center">
          {countrySelector ? (
            <CountrySelector
              handleOnChange={handleCountryChange}
              showFlags
              showCountryName
              color="text-black"
            />
          ) : null}
        </div>
      </div>
    </PortalModal>
  );
}

GeolocationModal.displayName = 'GeolocationModal';
